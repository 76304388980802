<template>
  <div class="space-y-10">
    <asom-card>
      <asom-grid :sm="1" :md="2">
        <asom-form-field label="Date">
          <asom-input-date-range v-model="filters.dateRange" />
        </asom-form-field>
        <div></div>
      </asom-grid>
      <template #footer>
        <asom-export-to-excel :fileName="`Collection Summary Report`"
          :fields="tableData.fields" 
          :labels="tableData.labels" 
          :data="tableData.data" />
        <asom-button
          text="Reset Filters"
          variant="secondary"
          @click="resetFilters"
        />
        <asom-button text="Apply" :disabled="isLoading" :is-loading="isLoading" @click="loadData" />
      </template>
    </asom-card>
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <div class="w-full text-center" v-else-if="isLoading">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <asom-client-table v-else
      :data="tableData.data"
      :columns="tableData.fields"
    >
      <template v-slot:header_id>Cash Box/Bag Id</template>
    </asom-client-table>
  </div>
</template>
<script>
import moment from 'moment';
import { getCollectionReport } from '../../../../services/cashManagement.service';
import { parseDateTime, displayUtcDateTime } from "@/helpers/dateTimeHelpers";

export default {
  data() {
    return {
      error: null,
      isLoading: false,
      filters: {
        dateRange: {
          start: moment().toDate(),
          end: moment().add(1, 'days').toDate()
        }
      },
      list: []
    }
  },
  mounted() {
    this.loadData()
  },
  computed: {
    tableData() {
      return {
        fields: [ 'station', 'transactionSource', 'transactionType', 'id', 'transactionDate', 'collectionDate', 'registeredValue', 'collectedValue', 'countedValue', 'diff', 'status' ],
        labels: [ 'Station', 'Transaction Source', 'Transaction Type', 'Cash Box/Bag Id', 'Transaction Date & Time', 'Collection Date & Time', 'Registered Value (A)', 'Cash Collected', 'Counted Value (B)', 'Diff (B - A)', 'Status'],
        data: this.list.map(item => ({
          ...item,
          transactionDate: displayUtcDateTime(item.transactionDate, 'DD/MM/YYYY'),
          collectionDate: displayUtcDateTime(item.collectionDate, 'DD/MM/YYYY'),
          status: this.getTransactionStatus(item),
        }))
      }
    }
  },
  methods: {
    getTransactionStatus({ availableInASOMS, availableInCertis, isMismatched }) {
      if (!availableInCertis)
        return 'Data not available from Certis'
      if (isMismatched)
        return 'Data mismatching'
      if (!availableInASOMS)
        return 'Data not available in ASOMS'
      return '';
    },
    resetFilters() {
      this.filters.start = moment().toDate();
      this.filters.end = moment().add(1, 'days').toDate();
    },
    async loadData() {
      if (!this.filters.dateRange.start || !this.filters.dateRange.end)
        return;
      this.error = null;
      this.isLoading = true;
      this.list = [];
      const resp = await getCollectionReport({
        startDate: parseDateTime(this.filters.dateRange.start, false),
        endDate: parseDateTime(this.filters.dateRange.end, false)
      })
      if (resp.success) {
        this.list = resp.payload.list || [];
      } else {
        this.error = resp.payload;
      }

      this.isLoading = false;
    }
  }
}
</script>